
<template>
<div class="container">
    <div class="row justify-content-center pt-5">
        <div class="col-md-8 col-lg-6">
            <div class="card">
                <div class="card-body p-4">
                    <a class="d-flex justify-content-center" :href="absoluteUrl()">
                        <img width="120" :src="absoluteUrl('/images/logos/logo-dark.svg')" :alt="$store.state.appName" class="navbar-brand-img">
                    </a>
                    <form action="#" class="mt-4">
                        <div class="form-group mb-3">
                            <label for="emailaddress">Email address</label>
                            <input v-model="data.email" v-validate="{ required: true, email: true }" name="emailaddress" :class="{ 'is-invalid': errors.has('emailaddress') }" class="form-control" type="email" id="emailaddress" placeholder="Enter your email" />
                        </div>
                        <div class="form-group mb-3">
                            <label for="password">Password</label>
                            <div class="input-group input-group-merge">
                                <input v-validate="'required|max:50'"
                                v-model="data.password" name="password"
                                :class="{ 'is-invalid': errors.has('password') }" :type="passwordType" id="password"
                                 class="form-control border-right-0" placeholder="Enter your password" />
                                <div class="input-group-append " style="cursor: pointer;" @click="passwordVisibility()">
                                    <div class="input-group-text bg-transparent border-left-0">
                                        <span class="password-eye"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="custom-control custom-checkbox">
                                <input v-model="data.remember" type="checkbox" class="custom-control-input" id="checkbox-signin" checked />
                                <label class="custom-control-label" for="checkbox-signin">Remember me</label>
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <button @click.prevent="submitData()" class="btn btn-block btn-primary" type="button">Log In</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      data: {
        email: '',
        password: '',
        role: 'admin',
        remember: true
      },
      passwordType: "password",

    }
  },
  methods: {
    passwordVisibility(){
        this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(this.absoluteUrl('/api/login'), formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.commit('SET_AUTH_TOKEN', response.data.data);
            location.replace(this.$route.query.intended || '/')
            this.resetForm()
          }
        })
      });
    },
    resetForm(){
      this.data = {
        email: '',
        password: '',
        role: 'admin',
        remember: true
      }
      this.$validator.reset()
    },
   },
  }
</script>
